import React, { useState, useEffect, memo, VFC, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Box,
  Typography,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import { studentList } from '../../types/studentList';
import { guideState, canceledCondition, CANCELED_CONDITION } from '../../types/guideState';
import { studentCourse } from '../../types/studentCourse';
import { StudentDetailsDialog } from '../../common/dialog/studentDetailsDialog';
import { DisplayDialogLink } from './displayDialogLink';
import { DeleteStudentDetailsDialog } from '../../common/dialog/deleteStudentDetailsDialog';
import { CreateStudentUnlimitedCourseDialog } from '../../common/dialog/CreateStudentUnlimitedCourseDialog';
import { DefaultSelectBox } from '../../common/selectBox/defaultSelectBox';
import { displayConditionMenuItems, getGuideStateMenuItems } from '../../common/selectMenuItems';
import { searchCondition, order, orderDirection } from '../../types/searchCondition';
import { parse, addDays, isAfter, addMonths, startOfMonth } from 'date-fns';
import { SelectMenuItem } from '../../types/item';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Alert } from '@material-ui/lab';
import { isAutoTrial, isTrial } from '../../utils/student';
import { RoleContext } from '../../providers/roleContext';

type Props = {
  studentList: studentList[];
  addUpdateGuideStateList: (guideState: guideState) => void;
  addUpdateStudentCourseList: (studentCourse: studentCourse) => void;
  deleteStudentCourseDetails: (studentCourseId: number) => void;
  createStudentUnlimitedCourse: (studentCourse: studentCourse) => void;
  sortStudentList: (order: order, orderDirection: orderDirection) => void;
};

type MenuItems = {
  displayCondition: SelectMenuItem[];
  guideState: SelectMenuItem[];
};

export const ListTable: VFC<Props> = memo(
  ({
    studentList,
    addUpdateGuideStateList,
    deleteStudentCourseDetails,
    createStudentUnlimitedCourse: createStudentUnlimitedCourse,
    sortStudentList,
  }) => {
    const classes = useStyles();
    const [perNumber, setPerNumber] = useState<number>(81);
    const [isShow, setIsShow] = useState<boolean>(false);
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState<boolean>(false);
    const [isShowCreateDialog, setIsShowCreateDialog] = useState<boolean>(false);
    const [studentId, setStudentId] = useState<number>(0);
    const [studentName, setStudentName] = useState<string>('');
    const [studentCourseId, setStudentCourseId] = useState<number>(0);
    const [orderDirection, setOrderDirection] = useState<orderDirection>('desc');
    const [order, setOrder] = useState<order>('requestOn');
    const [refinedStudentList, setRefinedStudentList] = useState<studentList[]>(studentList);
    const { isSpStaff } = useContext(RoleContext);

    const menuItems = useMemo((): MenuItems => {
      return {
        displayCondition: displayConditionMenuItems,
        guideState: getGuideStateMenuItems().slice(1),
      };
    }, []);

    useEffect(() => {
      const displayCondition = sessionStorage.getItem('displayCondition')
        ? JSON.parse(sessionStorage.getItem('displayCondition') || '')
        : 'BOTH';
      // 講師陣・企業向けにはキャンセル者を表示しない
      refineStudentList(displayCondition);
      if (sessionStorage.getItem('searchCondition')) {
        const searchCondition: searchCondition = JSON.parse(sessionStorage.getItem('searchCondition') || '');
        setOrderDirection(searchCondition.orderDirection);
        setOrder(searchCondition.order);
      }
    }, [studentList]);

    // 多次元配列になっているstudentListを分割する
    const studentCourdeDetailsList = useMemo(
      () =>
        studentList
          .map((student) => {
            return student.studentCourseDetailsList;
          })
          .flat(),
      [studentList]
    );

    const guideStateList = useMemo(
      () => studentCourdeDetailsList.map((studentCourdeDetails) => studentCourdeDetails.guideState),
      [studentCourdeDetailsList]
    );

    const loadList = () => {
      setPerNumber(perNumber + 81);
    };

    const loadAllList = () => {
      setPerNumber(perNumber + studentList.length);
    };

    const handleChangeForGuideState = (
      targetId: number,
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      const updateTarget = guideStateList.find((guideState) => guideState.studentCourseId === targetId);
      if (updateTarget) {
        updateTarget.guideState = typeof event.target.value === 'string' ? event.target.value : ``;
        addUpdateGuideStateList(updateTarget);
      }
    };

    const handleChangeForDisplay = (
      event: React.ChangeEvent<{ name?: string | undefined; value: 'BOTH' | 'NOT_CANCELED' | 'CANCELED' }>
    ) => {
      sessionStorage.setItem('displayCondition', JSON.stringify(event.target.value));
      refineStudentList(event.target.value);
    };

    const openDialog = (studentId: number, studentName: string) => {
      setIsShow(true);
      setStudentId(studentId);
      setStudentName(studentName);
    };

    const closeDialog = () => {
      setIsShow(false);
    };

    const openDeleteDialog = (studentCourseId: number, studentName: string) => {
      setIsShowDeleteDialog(true);
      setStudentName(studentName);
      setStudentCourseId(studentCourseId);
    };

    const closeDeleteDialog = () => {
      setIsShowDeleteDialog(false);
    };

    const closeCreateDialog = () => {
      setIsShowCreateDialog(false);
    };
    const sort = (orderTarget: order) => {
      let newOrderDirection: orderDirection;

      if (order == orderTarget) {
        newOrderDirection = orderDirection == 'desc' ? 'asc' : 'desc';
      } else {
        // 初回ソートは全てdescとする
        newOrderDirection = 'desc';
      }
      setOrder(orderTarget);
      setOrderDirection(newOrderDirection);
      sortStudentList(orderTarget, newOrderDirection);
    };

    const refineStudentList = (displayCondition: canceledCondition) => {
      if (displayCondition === CANCELED_CONDITION.BOTH) {
        return setRefinedStudentList(studentList);
      }

      const newStudentList = studentList.filter(function (student) {
        return student.studentCourseDetailsList[0].guideState.canceled ==
          (displayCondition === CANCELED_CONDITION.NOT_CANCELED)
          ? false
          : true;
      });
      setRefinedStudentList(newStudentList);
    };

    const courseStatus = useMemo(
      () =>
        refinedStudentList?.map((studentCourseDetails) => {
          // 無料体験チェック
          if (studentCourseDetails.studentCourseDetailsList[0].studentCourse.freeTrial) {
            if (studentCourseDetails.studentCourseDetailsList[0].guideState.canceled) {
              return classes.canceledFreeTrial;
            }
            return classes.freeTrial;
          }
          // キャンセルチェック
          if (studentCourseDetails.studentCourseDetailsList[0].guideState.canceled) {
            return classes.canceled;
          }
          // 受け放題コースによって追加されたコースの場合はトライアル表示対象外
          if (
            studentCourseDetails.studentCourseDetailsList[0].studentCourse.remark?.includes('受け放題コースでの追加')
          ) {
            return classes.additions;
          }
          // 仮申し込みの場合はトライアル表示対象外
          if (studentCourseDetails.studentCourseDetailsList[0].guideState.guideState === '仮申し込み') {
            return classes.root;
          }

          const requestOn = parse(
            studentCourseDetails.studentCourseDetailsList[0].studentCourse.requestOn,
            'yyyy-MM-dd',
            new Date()
          );
          // 強制トライアル期間開始日（申込日の翌々月1日から）
          const startTrialDate = startOfMonth(addMonths(requestOn, 2));
          // 強制トライアル期間終了の日付
          const expirationDate = addDays(startTrialDate, 14);

          if (studentCourseDetails.studentCourseDetailsList[0].studentCourse.invitedOn) {
            const invitedOn = parse(
              studentCourseDetails.studentCourseDetailsList[0].studentCourse.invitedOn,
              'yyyy-MM-dd',
              new Date()
            );

            if (isAfter(invitedOn, expirationDate)) {
              // Slack・Discord参加日が強制トライアル期間後の場合、トライアル期間終了
              return classes.root;
            } else if (isAfter(startTrialDate, invitedOn)) {
              // Slack・Discord参加日が強制トライアル期間前の場合、現在の日付とトライアル期間終了日を比較
              return isTrial(invitedOn) ? classes.trial : classes.root;
            } else {
              // Slack・Discord参加日が強制トライアル期間中の場合、現在の日付と強制トライアル期間を比較
              return isAutoTrial(startTrialDate, expirationDate) ? classes.trial : classes.root;
            }
          } else {
            return isAutoTrial(startTrialDate, expirationDate) ? classes.trial : classes.root;
          }
        }),
      [refinedStudentList]
    );

    const [open, setOpen] = useState(false);

    return (
      <StudentBox>
        <StudentBoxOption>
          <DisplayConditionBox>
            表示条件
            <DefaultSelectBox
              menuItems={menuItems.displayCondition}
              name="displayCondition"
              defaultValue={CANCELED_CONDITION.BOTH}
              handleChange={handleChangeForDisplay}
            />
          </DisplayConditionBox>
          <DisplayConditionRightBox>
            <Box display="flex">
              <Square color="#a1d699" />
              無料体験
            </Box>
            <Box display="flex">
              <Square color="#e6c573" />
              トライアル期間
            </Box>
            <Box display="flex">
              <Square color="#AFCDE9" />
              追加コース(受け放題)
            </Box>
          </DisplayConditionRightBox>
        </StudentBoxOption>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellOfHead onClick={() => sort('Student.id')}>
                  No
                  {order == 'Student.id' && (
                    <Icon className={orderDirection == 'desc' ? 'fas fa-arrow-down' : 'fas fa-arrow-up'}></Icon>
                  )}
                </TableCellOfHead>
                <TableCell>コースID</TableCell>
                <TableCellOfHead onClick={() => sort('requestOn')}>
                  申込日
                  {order == 'requestOn' && (
                    <Icon className={orderDirection == 'desc' ? 'fas fa-arrow-down' : 'fas fa-arrow-up'}></Icon>
                  )}
                </TableCellOfHead>
                <TableCell>決済日</TableCell>
                <TableCellOfHead onClick={() => sort('invitedOn')}>
                  Slack
                  <br />
                  Discord招待日
                  {order == 'invitedOn' && (
                    <Icon className={orderDirection == 'desc' ? 'fas fa-arrow-down' : 'fas fa-arrow-up'}></Icon>
                  )}
                </TableCellOfHead>
                <TableCellOfHead onClick={() => sort('kname')}>
                  お名前
                  {order == 'kname' && (
                    <Icon className={orderDirection == 'desc' ? 'fas fa-arrow-down' : 'fas fa-arrow-up'}></Icon>
                  )}
                </TableCellOfHead>
                <TableCell>Discord名</TableCell>
                <TableCell>DiscordID</TableCell>
                <TableCell>
                  受講 <br />
                  コース
                </TableCell>
                {!isSpStaff && <TableCell>申し込み状況</TableCell>}
                <TableCell>メールアドレス</TableCell>
                {!isSpStaff && <TableCell>削除</TableCell>}
              </TableRow>
            </TableHead>
            {isShow && (
              <StudentDetailsDialog
                isShow={isShow}
                closeDialog={closeDialog}
                studentId={studentId}
                studentName={studentName}
              />
            )}
            {isShowDeleteDialog && (
              <DeleteStudentDetailsDialog
                isShowDeleteDialog={isShowDeleteDialog}
                closeDeleteDialog={closeDeleteDialog}
                studentCourseId={studentCourseId}
                studentName={studentName}
                deleteStudentCourseDetails={deleteStudentCourseDetails}
              />
            )}
            {isShowCreateDialog && (
              <CreateStudentUnlimitedCourseDialog
                studentId={studentId}
                studentName={studentName}
                isShowCreateDialog={isShowCreateDialog}
                closeCreateDialog={closeCreateDialog}
                createStudentUnlimitedCourse={createStudentUnlimitedCourse}
              />
            )}
            <TableBody>
              {refinedStudentList.slice(0, perNumber).map((student, i) => (
                <TableRow
                  key={'studentList' + student.studentCourseDetailsList[0].studentCourse.id}
                  className={courseStatus[i]}
                >
                  <TableCell component="th" scope="row">
                    {student.student.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {student.studentCourseDetailsList[0].studentCourse.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {student.studentCourseDetailsList[0].studentCourse.requestOn}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {student.studentCourseDetailsList[0].studentCourse.settlementOn}
                  </TableCell>
                  <CustomTableCell component="th" scope="row">
                    {student.studentCourseDetailsList[0].studentCourse.invitedOn}
                  </CustomTableCell>
                  <CustomTableCell component="th" scope="row" width={200}>
                    <DisplayDialogLink
                      studentName={student.student.name}
                      studentId={student.student.id}
                      openDialog={openDialog}
                    />
                  </CustomTableCell>
                  <CustomTableCell component="th" scope="row" width={250}>
                    {student.discordUser.name}
                  </CustomTableCell>
                  <TableCell component="th" scope="row">
                    {student.discordUser.discordId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <p>{student.studentCourseDetailsList[0].course.courseName}</p>
                    {student.studentCourseDetailsList[0].unlimitedCourse.unlimitedCourseName}
                  </TableCell>
                  {!isSpStaff && (
                    <CustomTableCell component="th" scope="row" width={350}>
                      <DefaultSelectBox
                        menuItems={menuItems.guideState}
                        name="displayCondition"
                        defaultValue={student.studentCourseDetailsList[0].guideState.guideState}
                        handleChange={handleChangeForGuideState}
                        value={student.studentCourseDetailsList[0].guideState.studentCourseId}
                        styles={{ width: 100 }}
                      />
                    </CustomTableCell>
                  )}
                  <CustomTableCell component="th" scope="row" width={300}>
                    <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
                      <Typography>{student.student.email}</Typography>
                      <IconButton
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(student.student.email);
                          setOpen(true);
                        }}
                      >
                        <FileCopyOutlinedIcon fontSize="small" color="inherit" />
                      </IconButton>
                    </Box>
                  </CustomTableCell>
                  {!isSpStaff && (
                    <TableCell component="th" scope="row">
                      <a
                        onClick={() => {
                          openDeleteDialog(student.studentCourseDetailsList[0].studentCourse.id, student.student.name);
                        }}
                      >
                        <i className="far fa-trash-alt"></i>
                      </a>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {studentList.length > perNumber ? <DisplayMore onClick={loadList}>さらに表示する</DisplayMore> : ''}
        {studentList.length > perNumber ? <DisplayMore onClick={loadAllList}>全件表示する</DisplayMore> : ''}
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => {
            setOpen(false);
          }}
          message=""
        >
          <Alert
            onClose={() => {
              setOpen(false);
            }}
            severity="success"
          >
            Copyされました
          </Alert>
        </Snackbar>
      </StudentBox>
    );
  }
);

const StudentBox = styled.div`
  overflow: scroll;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
`;

const StudentBoxOption = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DisplayConditionBox = styled.div`
  white-space: nowrap;
`;

const DisplayConditionRightBox = styled.div`
  display: flex;
  padding-top: 7px;
  gap: 24px;
`;

const Square = styled.span<{ color: string }>`
  width: 20px;
  height: 20px;
  ${({ color }) => `background: ${color}`};
  margin-right: 4px;
`;

const DisplayMore = styled.a`
  margin-right: 45px;
  cursor: pointer;
  color: #2498b3;
`;

const Icon = styled.i`
  bottom: 10px;
  right: 3px;
  position: absolute;
`;

const TableCellOfHead = styled(TableCell)`
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #196a7d;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`;

const CustomTableCell = styled(TableCell)`
  max-width: ${(props) => props.width}px;
  min-width: 80px;
  overflow: scroll hidden;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#f2f2f2',
      },
    },
    canceled: {
      backgroundColor: '#d8d8d8',
    },
    canceledFreeTrial: {
      backgroundColor: '#d8d8d8',
      borderLeft: '3px solid #a1d699',
    },
    freeTrial: {
      backgroundColor: '#a1d699',
    },
    trial: {
      backgroundColor: '#e6c573',
    },
    additions: {
      backgroundColor: '#AFCDE9',
    },
    labelRoot: {
      marginLeft: '16px',
    },
  })
);

ListTable.displayName = 'ListTable';
